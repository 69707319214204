/*v CHARACTER v*/
#character {
    left: 755px;
    top: 160px;
    transform: scale(1);
    z-index: 1;
    width: 30px;
}

/*v Upper Body v*/
#character, .mynose, .mysmile, .myhead_highlight, .neck, .neck_shadow, 
.mybody, .left_sleeve, .left_arm, .left_arm_shadow, .right_sleeve,
.right_arm, .right_forearm, .right_forearm_safari, .legs, .leg_leftShadow, .leg_rightShadow,
.right_shoe, .right_cuff, .left_cuff, .left_shoe, .mylefteye, .myrighteye, .myleftear, .myrightear,
.myhair1, .myhair2, .myhair3, .myhair4, .myhair5, .myhair6, .myhair7 {
    position: absolute;
}

.neck, .neck_shadow, .myhead_highlight, .myleftear, .left_arm_shadow, .right_arm, .right_forearm, .right_forearm_safari {
    background-color: burlywood;
}

.myhead, .myhead_highlight, .left_shoe, .right_sleeve {
    z-index: 1;
}

.myhair1, .myhair2, .myhair3, .myhair4, .myhair5, .myhair6, .myhair7, .mylefteye, .myrighteye,
.mynose, .mysmile, .left_sleeve, .legs, .leg_leftShadow, .leg_rightShadow, .left_cuff, .right_cuff, .right_shoe {
    z-index: 2;
}

/*v Head v*/
.myhair1, .myhair2, .myhair3, .myhair4, .myhair5, .myhair6, .myhair7 {
    background-color: black;
    background-color: #161616;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}

.myhead, .myhead_highlight, .neck_shadow {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.myhead {
    height: 80px;
    width: 75px;
    border-color:#212427;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    left: 388px;
    top: 250;
}

.myhair1, .myhair3, .myhair5 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.myhair2, .myhair4, .myhair6, .myhair7, .mysmile {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.myhair1 {
    height: 40px;
    width: 65px;
    top: 240px;
    left: 390px;
    box-shadow: inset 5px 2px 0px #333, inset -7px -2px 0px black;
    z-index: 3;
}

.myhair2 {
    height: 30px;
    width: 25px;
    top: 255px;
    left: 377px;
    box-shadow: inset 5px 2px 0px #333;
    z-index: 3;
}

.myhair3 {
    height: 35px;
    width: 3px;
    top: 270px;
    left:386px;
}

.myhair4 {
    height: 30px;
    width: 25px;
    top: 255px;
    left: 443px;
    box-shadow: inset -5px -2px 0px black;
    z-index: 3;
}

.myhair5 {
    height: 35px;
    width: 3px;
    top: 270px;
    left: 457px;
    background-color: black;
    z-index: 1;
}

.myhair6 {
    height: 25px;
    width: 20px;
    top: 247px;
    left: 440px;
    box-shadow: inset -5px -2px 0px black;

}

.myhair7 {
    height: 25px;
    width: 20px;
    top: 247px;
    left: 384px;
    box-shadow: inset 2px 4px 0px #333;
    z-index: 4;
}

@keyframes blink {
    0% {
        height: 15px;
        top: 283px;
    }
    3%{
        height: 0px;
        top: 296px;
    }
    6%{
        height: 15px;
        top: 283px;
    }
}

.mylefteye, .myrighteye {
    height: 15px;
    width: 13px;
    background-color: #ad8f68;
    top: 283px;
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    animation: blink 10s infinite;
}

.mylefteye {
    left: 403px;
}

.myrighteye {
    left: 432px;
}

.myleftear, .myrightear {
    height: 10px;
    width: 7px;
    top: 300px;
}

.myleftear {
    left: 382px;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    background-color: #dcc5a8;
}

.myrightear {
    background-color: #ba996f;
    left: 457px;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
}

.mynose {
    height: 5px;
    width: 10px;
    top: 304px;
    left: 419.5px;
    background-color: #ad8f68;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.mysmile {
    height: 10px;
    width: 20px;
    background-color: darkred;
    top: 320px;
    left: 415px;
}

.myhead_highlight {
    height: 88px;
    width: 70px;
    top: 255px;
    left: 388px;
    border-color:black;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    box-shadow: inset -2px -1px 0px 1px #ba996f, inset 7px 2px 0px 1px #d9c0a2;
}
/*^ Head ^*/

.neck {
    height: 50px;
    width: 20px;
    top: 330px;
    left: 415px;
    box-shadow: inset -1px -2px 0px 1px #ba996f, inset 2px 1px 0px 0px #d9c0a2 ;
}

.neck_shadow {
    height: 20px;
    width: 30px;
    top: 323px;
    left: 410px;
}

.mybody, .left_sleeve, .right_sleeve { 
    background-color: #e8eaed;
}

/*v Torso v*/
@keyframes bodyBreathing{
    0%{
        top: 350px;
        width: 50px;
    }
    50%{
        top: 349px;
        width: 51px;
    }
    100%{
        top: 350px;
        width: 50px;
    }
}

.mybody {
    height: 76px;
    width: 50px;
    top: 350px;
    left: 400px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 3;
    box-shadow: inset -5px -3px 0px #d0d4db;
    animation: bodyBreathing 3s infinite;
}

@keyframes pocketBreathing {
    0%{
        top: 365px;
    }
    50% {
        top: 364px;
    }
    100% {
        top: 365px;
    }
}

.mybody-pocket {
    position: absolute;
    height: 15px;
    width: 15px;
    top: 365px;
    left: 430px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    animation: pocketBreathing 3s infinite;
    background-color: #e8eaed;
    box-shadow: inset -2px -2px 0px #d0d4db;
    z-index: 4;
}

.left_sleeve {
    height: 30px;
    width: 18px;
    top: 355px;
    left: 390px;
    -webkit-transform: rotate(50deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(50deg);
    box-shadow: inset -5px 2px 3px #d0d4db;
    animation: leftSleeveBreathing 3s infinite;
}

.left_arm, .left_arm_shadow {
    height: 50px;
    width: 13px;
    top: 355px;
    left: 380px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    -webkit-transform: rotate(50deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(50deg);
}

.left_arm {
    background-color: #ffffde;
    box-shadow: inset -1px -2px 0px 1px #ba996f;
    animation: leftSleeveBreathing 3s infinite;
}

@keyframes leftArmBreathing {
    0%{
        top: 355px;
    }
    50%{
        top: 354px;
    }
    100%{
        top: 355px;
    }
    
}

.left_arm_shadow {
    box-shadow: inset -1px 1px 0px 1px #ba996f, inset 3px 1px 0px 0px #d9c0a2;
    animation: leftArmBreathing 3s infinite;
}

.right_sleeve, .right_forearm, .right_forearm_safari {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.right_sleeve {
    height: 30px;
    width: 18px;
    top: 347px;
    left: 440px;
    animation: rightSleeveBreathing 3s infinite;
    box-shadow: inset -5px 1px 0px #d0d4db;
}

.right_arm {
    height: 13px;
    width: 53px;
    top: 356px;
    left: 425px;
    animation: rightArmBreathing 3s infinite;
    box-shadow: inset 0px -2px 0px 0cap #ba996f 
}

@keyframes handWave {
    0% {
        left: 456px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        box-shadow: inset -2px 2px 0px #ba996f, inset 1px -2px 0px 0px #d9c0a2;; 
    }
    50%{
        -webkit-transform: rotate(110deg);
        -moz-transform: rotate(110deg);
        -o-transform: rotate(110deg);
        -ms-transform: rotate(110deg);
        transform: rotate(110deg);
        left: 459px;
        box-shadow: inset 0px .5px 0px 0cap #ba996f, inset 2px -2px 0px 0px #d9c0a2;

    }
    100%{
        left: 456px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        box-shadow: inset -2px 2px 0px #ba996f, inset 1px -2px 0px 0px #d9c0a2;
    }
}

@keyframes rightForearmBreathing{
    0%{
        top: 345px;
    }
    50%{
        top: 344px;
    }
    100%{
        top: 345px;
    }
}

.right_forearm {
    height: 13px;
    width: 39px;
    top: 346px;
    left: 456px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 10px;
    animation: handWave .25s 7, rightForearmBreathing 3s infinite;
    -webkit-animation: handWave .3s 7, rightForearmBreathing 3s infinite;
    box-shadow: inset -2px 2px 0px #ba996f, inset 1px -2px 0px 0px #d9c0a2; 
    z-index: -1;
}  

@keyframes handWaveSafari {
    0% {
        left: 456px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    50%{
        
        -webkit-transform: rotate(100deg);
        -moz-transform: rotate(100deg);
        -o-transform: rotate(100deg);
        -ms-transform: rotate(100deg);
        transform: rotate(100deg);
        left: 458px;
    }
    100%{
        
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        left: 456px;
    }
        
}

.right_forearm_safari{
    height: 13px;
    width: 40px;
    top: 345px;
    left: 455px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 15px;
    box-shadow: inset -1px 2px 0px 0cap #ba996f;
    animation: rightForearmBreathing 3s infinite;
    animation: handWaveSafari .5s 5, rightForearmBreathing 3s infinite;
}
/*^ Upper Body ^*/

/*v Lower Body v*/
.legs, .leg_leftShadow, .leg_rightShadow {
    height: 70px;
    top: 390px;
}

.leg_leftShadow, .leg_rightShadow {
    width: 5px;
    background-color: #5e90db;
}

.legs {
    width: 35px;
    background-color: #75aaf9;
    left: 407px;
    box-shadow: inset 5px -0px 0px 0px #83b4fd;
}

.leg_leftShadow {
    left: 420px;
}

.leg_rightShadow {
    left: 437px;
}

.left_cuff, .right_cuff {
    height: 8px;
    background-color: skyblue;
    top: 459px;
    box-shadow: inset -3px -1px 0px 1px #73b1c9;
}

.right_cuff {
    width: 27px;
    left: 425px;
}

.left_cuff {
    width: 30px;
    left: 395px;
}

.left_shoe, .right_shoe {
    height: 18px;
    width: 33px;
    top: 465px;
    background-color: white;
    box-shadow: inset -3px -1px 0px 1px #9b7676;
    background-color: rosybrown;
}

.left_shoe {
    left: 425px; 
    border-top-right-radius: 15px;
}

.right_shoe {
    left: 390px; 
    border-top-left-radius: 45px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 10px;
    box-shadow: inset -3px -1px 0px 1px #9b7676, inset 3px 1px 0px 0px #b99a9a;
}

@keyframes speechFloat {
    0% {
        top: 40px;
    }
    50% {
        
        top: 45px;
    }
    100% {
        top: 40px;
    }
}

@keyframes speechAppear {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@font-face {
    font-family: "dogica";
    src: url("dogica.ttf");
}

.speech-bubble {
    position: relative;
    height: 100px;
    width: 200px;
    background-color: white;
    left: 500px;
    border-radius: 15px;
    z-index: 5;
    padding: 5px;
    animation: speechFloat 1.5s infinite, speechAppear 6s 1 forwards;
    font-size: 14px;
    top: 40px;
    box-shadow: inset -2px -2px 1px #aaaaaa;
}

.bubble-tail{
    width: 0;
    height: 0;
    border-left: 10px solid transparent; /* Adjust width as needed */
    border-right: 10px solid transparent; /* Adjust width as needed */
    border-bottom: 35px solid white; 
    position: absolute;
    top: 92px;
    left: -14px;
    transform: rotate(-135deg);
    
}

.speech-text {
    font-family: 'dogica', sans-serif;
}

@media only screen and (max-width: 3300px) and (min-height: 1725px)  {
    #character {
        transform: scale(3);
        left: 20px;
        top: 1100px;
    }

    @keyframes speechFloat {
        0% {
            top: 50px;
        }
        50% {
            
            top: 55px;
        }
        100% {
            top: 50px;
        }
    }

    .bubble-tail{
        left: -10px;
        top: 90px;
    }

    .speech-bubble {
        left: 406px;
        transform: scale(.6);
        animation: speechFloat 1.5s infinite;
        font-size: 14px;
        top: 60px;
        width: 160px;
        animation: speechFloat 1.5s infinite, speechAppear 6s 1 forwards;
    }
}