

.home2, .bio2, .resume2, .audio2, .video2, .blog2, .portfolio2, .wrapper, .wrappertext, .cvTitle, .bio-tbar {
    position: absolute;
}

.home2:hover, .bio2:hover, .resume2:hover, .audio2:hover, .video2:hover, .blog2:hover  {
    -webkit-filter: invert(100%);
}

.bio2, .resume2, .audio2, .video2, .blog2, .portfolio2, .home2 {
    transform: rotate(45deg);
    box-shadow: 2px 2px 2px #000;
    -webkit-transition: all 1s;
    -webkit-filter: invert(0%);
    height: 50px;
    margin-left: 767px;
}

.home2 {
    margin-top: 100px;
}

.resume2 {
    margin-top: 175px;
}

.audio2 {
    margin-top: 250px;
}

.video2 {
    margin-top: 325px;
}

.blog2 {
    margin-top: 400px;
}

.portfolio2 {         
    margin-top: 475px;
}

.degreetxt {
    font-size: 16px;
}

.degree {
    font-size: 18px;
}

.bio-wrapper {
    width: 750px;
    height: 100vh;
    background-color: #ffffed;
    border: 2px solid #a8a8a8;
    font-family: "Helvetica";
    box-shadow: 2px 2px 2px #000;
    overflow-y: scroll;
    margin-top: -5px;
}

.bio-wrappertext {
    margin: 70px 7px 16px 7px;
    text-wrap: wrap;
}

.cvTitle {
    margin-right: 500px;
    padding-left: 250px;
    z-index: 4; 
}

.bio-tbar {
    width: 800px;
    height: 20px;
    background-color: #85BF7E;
    border-radius: 45px;
    margin: 40px 0px 0px -20px;
    box-shadow: -1px 2px 3px #080809;
}