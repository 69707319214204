@font-face {
    font-family: "Rightwood";
    src: url("Rightwood.ttf");
}

@font-face {
    font-family: "dogica";
    src: url("dogica.ttf");
}

/*v Animations v*/
@keyframes leftSleeveBreathing{
    0%{
        top: 355px;
    }
    50%{
        top: 354px;
    }
    100%{
        top: 355px;
    }
}

@keyframes rightSleeveBreathing{
    0%{
        top: 348px;
    }
    50%{
        top: 347px;
    }
    100%{
        top: 348px;
    }
}

@keyframes rightArmBreathing{
    0%{
        top: 358px;
    }
    50%{
        top: 357px;
    }
    100%{
        top: 358px;
    }
}

@keyframes flowerShadow{
    0%{
        width: 60px;
    }
    50%{
        width: 62px;
    }
    100%{
        width: 60px;
    }
}

@keyframes treeShadow{
    0%{
        width: 360px;
    }
    50%{
        width: 365px;
    }
    100%{
        width: 360px;
    }
}

@keyframes treeRustle{
    0% {
        left: 60px;
    }
    50% {
        left: 65px;
    }
    100% {
        left: 60px;
    }
}

@keyframes bulbRustle{
    0%{
        left: 0;
    }
    50%{
        left: 3;
    }
    100%{
        left: 0;
    }
}

@keyframes treeRustle1{
    0% {
        left: 140px;
    }
    50% {
        left: 145px;
    }
    100% {
        left: 140px;
    }
}

@keyframes treeRustle2{
    0% {
        left: 120px;
    }
    50% {
        left: 123px;
    }
    100% {
        left: 120px;
    }
}

@keyframes treeRustle3{
    0% {
        left: 200px;
    }
    50% {
        left: 203px;
    }
    100% {
        left: 200px;
    }
}

@keyframes treeRustle4{
    0% {
        left: 200px;
    }
    50% {
        left: 203px;
    }
    100% {
        left: 200px;
    }
}

@keyframes treeRustle5{
    0% {
        left: 60px;
    }
    50% {
        left: 65px;
    }
    100%{ 
        left: 60px;
    }
}

@keyframes treeRustle6{
    0% {
        left: 180px;
    }
    50% {
        left: 185px;
    }
    100% {
        left: 180px;
    }
}

@keyframes appleRustle{
    0%{
        left: 100px;
    }
    50%{
        left: 103px;
    }
    100%{
        left: 100px;
    }
}

@keyframes appleRustle1{
    0%{
        left: 110px;
    }
    50%{
        left: 113px;
    }
    100%{
        left: 110px;
    }
}

@keyframes appleRustle2{
    0%{
        left: 230px;
    }
    50%{
        left: 235px;
    }
    100%{
        left: 230px;
    }
}

@keyframes float3{
        0% {
            left:15vw;
            top: 80px;
        }
        100%{
            left: 110vw;
            top: 0px;
        }
}

@keyframes float4shadow{
    0% {
        left: calc(100vw + 150px);
    }
    100%{
        left: 180px;
    }
}

@keyframes flutterShadow{
    0%{
        height:  5px;
        width: 17px;
    }
    50%{
        height: 6px;
        width: 20px;
    }
    100%{
        height: 5px;
        width: 17px;
    }
}
/*^ Animations ^*/

@viewport {
    zoom: 1.0 !important;
    width: device-width;
    initial-scale: 1.0 !important;
    maximum-scale: 1.0 !important;
    user-scalable: no !important;
}

html, body {
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
}

body {
    padding: 0px;
    margin: 0px;
    font-family: helvetica;
    font-size: 12px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

#sky {
    background: linear-gradient(to bottom, rgba(67,160,165,1) 0%,rgba(83,199,204,1) 100%);
    height: 60%;
    width: 100vw;
    z-index: -6;
    top: 0;
}

#sky, #react, #tree, #stem, #stemShadow, #stemHole, #stemBranch, #treeTop1, #treeTop2,
#treeTop3, #treeTop4, #treeTop5, #treeTop6, #apple1, #apple2, #apple3, .birdStyle, .cloud1,
.cloud2, .cloud3, .cloud4, .cloud5, .cloud6, .puff1, .puff2, .puff3, .puff4, .puff1a, .puff2a,
.puff3a, .puff4a {
    position: absolute;
}

#treeTop1, #treeTop2, #treeTop4, #treeTop6, #birdpart6, .birdpart7, .puff2, .puff3, .puff2a, .puff3a {
    z-index: 0;
}

#stem, #stemShadow, #stemHole, #treeTop3, #treeTop5, #apple1, #apple2, #apple3, .birdpart8,
.puff1, .puff1a{
    z-index: 1;
}

#stem, #stemBranch {
    background-color: saddlebrown;
}

#treeTop1, #treeTop2, #treeTop3, #treeTop4, #treeTop5, #treeTop6 {
    background-color: darkgreen;
}

#react {
    bottom: 0px;
    right: 15px;
    color: white;
}

/*v Tree v*/
#tree {
    height: 500px;
    width: 350px;
    left: 1180px;
    top: 83px;
    z-index: 1;
}

#stem {
    width: 50px;
    height: 300px;
    left: 150px;
    top: 200px;
    box-shadow: inset 5px 0px 0px #ab5e27, inset -12px 0px 0px 0px #70401e;
}

#stemShadow {
    width: 15px;
    height: 300px;
    background-color: #562a0b;
    left: 190px;
    top: 200px;
    box-shadow: inset -2px 1px 0px 1px #3d1e09;
}

#stemHole {
    background-color: black;
    height: 50px;
    width: 10px;
    border-radius: 100px;
    top: 300px;
    left: 160px;
    box-shadow: -1px -1px 0px #562a0b;
}

#stemBranch {
    height: 80px;
    width: 10px;
    transform: rotate(-40deg);
    top: 190px;
    left: 130px;
    box-shadow: inset -1px 1px 0px 1px #70401e;   
    background-color: #ab5e27;
}

@keyframes treeRustle1{
    0%{
        left: 140px;
    }
    50%{
        left: 145px;
    }
    100%{
        left: 140px;
    }
}

#treeTop1 {
    height: 150px;
    width: 150px;
    top: 120px;
    left:140px;
    animation: treeRustle1 4s infinite;
    animation-delay: .5s;
    border-radius: 100px;
    box-shadow: inset -15px -20px 0px 1px rgb(15, 92, 20, 1), inset 1px 1px 0px 1px #027002;
}

@keyframes treeRustle2{
    0%{
        left: 120px;
    }
    50%{
        left: 123px;
    }
    100%{
        left: 120px;
    }
}

#treeTop2 {
    height: 70px;
    width: 70px;
    top: 150px;
    left: 120px;
    animation-delay: .25s;
    animation: treeRustle2 4s infinite;
    border-radius: 100px;
    z-index: 2;
    box-shadow: inset -6px -9px 0px 1px rgb(15, 92, 20, 1);
}

@keyframes treeRustle{
    0%{
        left: 60px;
    }
    50%{
        left: 65px;
    }
    100%{
        left: 60px;
    }
}

#treeTop3{
    height: 220px;
    width: 220px;
    top: 0px;
    left: 60px;
    border-radius: 100px;
    animation-delay: .5s;
    animation: treeRustle 4s infinite;
    box-shadow: inset -10px -15px 0px 1px rgb(15, 92, 20, 1), inset 20px 13px 0px 1px #027002;
}

@keyframes treeRustle3{
    0%{
        left: 200px;
    }
    50%{
        left: 203px;
    }
    100%{
        left: 200px;
    }
}

#treeTop4 {
    height: 100px;
    width: 100px;
    top: 0px;
    left: 200px;
    animation-delay: .25s;
    animation: treeRustle3 4s infinite;
    border-radius: 100px;
    box-shadow: inset -10px -6px 0px 1px rgb(15, 92, 20, 1), inset 2px 5px 0px 1px #027002;
}

@keyframes treeRustle5{
    0%{
        left: 60px;
    }
    50%{
        left: 65px;
    }
    100%{
        left: 60px;
    }
}

#treeTop5{
    height: 120px;
    width: 120px;
    top: 100px;
    left: 60px;
    animation-delay: .375s;
    animation: treeRustle5 4s infinite;
    border-radius: 100px;
    box-shadow: inset -8px -7px 0px 1px rgb(15, 92, 20, 1), inset 10px 8px 0px 1px #027002;
}

@keyframes treeRustle6{
    0%{
        left: 180px;
    }
    50%{
        left: 185px;
    }
    100%{
        left: 180px;
    }
}

#treeTop6 {
    height: 150px;
    width: 150px;
    top: 50px;
    left: 180px;
    animation-delay: .375s;
    animation: treeRustle6 4s infinite;
    border-radius: 100px;
    box-shadow: inset -10px -15px 0px 1px rgb(15, 92, 20, 1);
}

#apple1, #apple2, #apple3 {
    background-color: red;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

#apple1 {
    animation-delay:  .25s;
    animation-name:appleRustle;
    top: 100px;
    left: 100px;
    box-shadow: inset -2px -2px 0px 1px  rgb(188, 2, 2), inset 6px 7px 0px 1px #fa3737;
}

#apple2 {
    animation-delay: .42s;
    animation-name: appleRustle1;
    top: 200px;
    left: 110px;
    box-shadow: inset -4px -4px 0px 1px  rgb(188, 2, 2), inset 2px 2px 0px 1px #fa3737;
}

#apple3{
    animation-delay: .375;
    animation-name: appleRustle2;
    top: 180px;
    left: 230px;
    box-shadow: inset -4px -5px 0px 1px rgb(188, 2, 2), inset 2px 2px 0px 1px #fa3737;
}
/*^ Tree ^*/

@keyframes float4 {
    0% {
        left: 100vw;
    }
    100% {
        left: 30px;
    }
}

@keyframes flutter {
    0%{
        top:  200px;
    }
    50%{
        top: 215px;
    }
    100%{
        top: 200px;
    }
}


/*v Bird v*/
.bird {
    height: 50px;
    width: 50px;
    left: 1990px;
    top:  200px;
    transform:scaleX(-1);
    animation: float4 19s infinite, flutter 1s infinite;
    z-index: -3;
}

.birdShadow{
    position: absolute; 
    animation: float4shadow 19s infinite, flutterShadow 1s infinite;
    width: 25px;
    height: 7px;
    top: 530px;
    left: 1550px;
    background-color: darkgreen;
    border-radius: 90px;
    z-index: -3;
    opacity: .4;
}

.birdpart1, .birdpart2, .birdpart3, .birdpart4, .birdpart5 {
    background-color: red;
}

.birdpart1 {
    height:8px;
    width: 9px;
    border-radius: 50%;
    left: 30px;
    top: 7px;
}

.birdpart2 {
    height:10px;
    width: 11px;
    border-radius: 50%;
    left: 22px;
    top: 10px;
    transform: rotate(5deg);
}

.birdpart3 {
    height:1px;
    width: 27px;
    left: 8px;
    top: 11px;
    transform: rotate(-1deg);
}

.birdpart4 {
    height:1px;
    width: 27px;
    left: 10px;
    top: 10px;
    transform: rotate(-5deg);
}

.birdpart5 {
    height:10px;
    width: 14px;
    left: 16px;
    top: 11px;
    transform: rotate(-5deg);
    border-bottom-left-radius: 20px;
    box-shadow: inset 1px -1px 0px 1px darkred;
}

.birdpart6 {
    width: 0;
    height: 0;
    border-bottom: 3px solid orange;
    border-right: 5px solid transparent;
    border-bottom-right-radius:0px;
    left: 38px;
    top: 9px;
}

.birdpart7 {
    width: 0;
    height: 0;
    border-bottom: 3px solid red;
    border-right: 5px solid transparent;
    border-bottom-right-radius:0px;
    left: 32px;
    top: 5px;
}

@keyframes flap {
    0% {
        left: 20px;
        top: 2px;
        transform: rotate(-20deg);
    }
    50% {

        left: 20px;
        top: 13px;
        transform: rotate(-140deg);
    }
    100% {
        left: 21px;
        top: 2px;
        transform: rotate(-20deg);
    }
}

.birdpart8 {
    width: 8px;
    height: 15px;
    background-color: darkred;
    /* border-bottom: 8px solid darkred;
    border-right: 15px solid transparent; */
    border: 0px 10px 10px 0px;
    /* left: 19px;
    top: 5px; */
    top: -15px;
    transform: rotate(45deg);
    animation: flap .25s infinite;
    border-bottom-right-radius:0px;   
    border-bottom-left-radius:10px;
    border-top-left-radius:0px;
    border-top-right-radius:10px;
    /* z-index:2; */
}

.birdpart9 {
    height: 1px;
    width: 2px;
    border-radius: 50%;
    background-color: black;
    left: 36px;
    top: 9px;
    z-index: 2;
}
/*^ Bird ^*/

.cloud1, .cloud2, .cloud3, .cloud4, .cloud5, .cloud6, .cloud7, .cloud8 {
    animation-iteration-count: infinite;
    opacity: 1;
    z-index: -2;
}

@keyframes float{
    0% {
        left: 25vw;
    }
    100%{
        left: 105vw;
    }
}

.cloud1 {
    height: 80px;
    width: 55px;
    left: 0vw;
    top: 200px;
    left: 200px;
    animation-name: float;
    animation-duration: 195s;
    animation-delay: 7s;
    animation-iteration-count: infinite;
    z-index: -3;
}

@keyframes float2{
    0% {
        left: 18vw;
    }
    100%{
        left: 104vw;
    }
}

.cloud2 {
    height: 100px;
    width: 110px;
    left: 100vw;
    animation-name:float2;
    animation-duration: 130s;
    animation-delay: 40s;
    animation-iteration-count: infinite;
    z-index: -3;
}

.cloud3 {
    height:160px;
    width: 110px;
    left: 40vw;
    top: 100px;
    animation-name:float2;
    animation-duration: 90s;
    animation-iteration-count: infinite;
    z-index: -3;
    
}

.cloud4 {
    height:160px;
    width: 110px;
    left: 150px;
    top: 270px;
    animation-name:float2;
    animation-duration: 160s;
    animation-iteration-count: infinite;
    z-index: -3;
}

.cloud5 {
    height:160px;
    width: 110px;
    top: 200px;
    left: 40vw;
    animation-name:float2;
    animation-duration: 101s;
    animation-iteration-count: infinite;
    z-index: -3;
}

.cloud6 {
    height:160px;
    width: 110px;
    top: 110px;
    animation-name:float2;
    animation-duration: 100s;
    animation-iteration-count: infinite;
    z-index: -5;
}

.cloud7 {
    background-color: white;
    border-radius: 20px;
    height: 10px;
    width: 40px;
    top: 70px;
    position: absolute;
    animation-duration: 160s;
    animation-name:float2;
    animation-iteration-count: infinite;
    box-shadow:  inset -1px -1px 0px  #ddd;
    z-index: -3;
}

.cloud8 {
    background-color: white;
    border-radius: 20px;
    height: 7px;
    width: 20px;
    top: 350px;
    position: absolute;
    z-index: -3;
    animation-duration: 230s;
    animation-name:float2;
    animation-iteration-count: infinite;
    box-shadow:  inset -1px -1px 0px  #ddd;
    z-index: -4;

}

.puff1, .puff2, .puff3, .puff4, .puff1a, .puff2a, .puff3a, .puff4a {
    background-color: #ECECEC;
}

.puff2, .puff3, .puff4, .puff2a, .puff3a, .puff4a {
    border-top-right-radius: 50px;
    border-top-left-radius: 50px; 
}

.puff2, .puff3, .puff2a, .puff3a {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.puff1 {
    top: 75px;
    height: 5px;
    width: 50px;
    border-radius: 50px;
    box-shadow: inset -4px -2px 0px  #ddd, inset 12px -1px 0px 0px white;
}

.puff2 {
    top: 65px;
    left: 27px;
    height: 15px;
    width: 22px;
    box-shadow: inset -2px -2px 0px  #ddd;
}

.puff3 {
    top: 54px;
    left: 18px;
    height: 25px;
    width: 25px;
    box-shadow: inset 3px 1px 0px white;
}

.puff4 {
    top: 65px;
    left: 10px;
    height: 15px;
    width: 20px;
    box-shadow: inset 0px -4px 0px  #ddd, inset 5px -1px 0px 0px white;
}

.puff1a {
    top: 95px;
    height: 10px;
    width: 100px;
    border-radius: 50px;
    box-shadow: inset -5px -5px 0px  #ddd, inset 30px -1px 0px 0px white;
    z-index: 2;
}

.puff2a {
    top: 75px;
    left: 20px;
    height: 30px;
    width: 40px;
    box-shadow:  inset 10px 1px 0px white;
    z-index: 0;
}

.puff3a {
    top: 55px;
    left: 38px;
    height: 50px;
    width: 50px;
    box-shadow: inset 11px 1px 0px white;
    z-index: -1;
}

.puff4a {
    top: 69px;
    left: 60px;
    height: 30px;
    width: 40px;
    box-shadow: inset -5px -4px 0px  #ddd, inset 13px -1px 0px 0px white;
    z-index: -2;
}
/*^ Sky ^*/

.thumbnail1 {
    max-width: 125px;
    margin-right: 400px;
    border-style: solid;
    border-color: white;
    box-shadow: 3px 3px 0px #000;
}

.back-arrow {
    float: left;
    margin: 25px 0px 16px 12px;
    cursor: pointer;
    margin-left: 30px;
    transform: scale(2);
}

.back-box {
    float: left;
    height: 60px;
    width: 55px;
    cursor: pointer;
    z-index: 5;
    margin-left: 10px; 
}

.resumeContainer {
    height: fit-content;
    width: fit-content;
    margin: auto;
}

.resumeContainer div {
    float: left;
    height: 100%;
    border-radius: 5px;
    margin: 150px 25px;
}

.resume-thumbnail {
    float: left;
    height: 15vw;
    outline: #000 1px solid;
    border-radius: 5px;
    margin: 25px 25px;
    cursor: pointer;
}

.resume-subtitle {
    text-align: center;
    font-family: 'dogica', sans-serif;
    margin-top: 5px;
}

.portfolioClose {
    position: relative;
    float: right;
    right: 9px;
    top: 0px;
    font-size: 38px;
    background-color: red;
    height: 50px;
    width: 60px;
    border-radius: 5px;
    box-shadow: inset -3px -3px 0px 0px darkred;
    padding-top: 12px;
    cursor: pointer;
    border: 2px solid  rgb(63, 0, 0);
}

.portfolioClose:hover {
    background-color: rgb(132, 0, 0);
    box-shadow: inset 3px 3px 0px 0px rgb(63, 0, 0);
    padding-left: 3px;
    right: 9px;
    padding-top: 17px;
    width: 57px;
    height: 45px;
}

.xx {
    padding-top: 15px;
}

.portfolioHeader {
    height: 75px;
    margin: 0;
    padding-top: 10px;
    background-color: #1666e5;
    font-size: 16px;
    color: white;
    text-align: center;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    font-family: 'dogica', sans-serif;
    border-bottom: 2px solid #313131;
}

@keyframes fadein {
    0%{
        top: 13vh;
        opacity: 0%;
    }
    100% {
        top: 8vh;
        opacity: 100%;
    }
}

.portfolioContainer {
    position: absolute;
    width: 75vw;
    height: 83vh;
    background-color: #FCF5E5;
    left: 13vw;
    top: 8vh;
    z-index: 100;
    border: 3px solid black;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    animation: fadein .5s 1;
}

.portfolioBody {
    width: 100%;
    height: calc(100% - 87px);
    overflow-x: none;
    overflow-y: scroll;
    display: flexbox;
    flex-wrap: wrap;
}

.portfolioBody .img-container img {
    height: calc(95% - 90px);
    margin: 10px;
}

.portfolioBody .img-container {
    height: 500px;
    margin-bottom: 0px;
}

.picture-sub {
    text-align: center;
    width: 100%;
    font-family: 'dogica', sans-serif;
}

.portfolioBody div img {
    margin: 10px;
}

.portfolioHeader p {
    margin: 0 auto;
    text-align: center;
    display: inline;
}

.portfolioTitle {
    font-size: 36px;
    text-align: center;
    position: relative;
    top: 15px;
}

.resume-title {
    left: 30px;
    font-size: 36px;
    text-align: center;
    position: relative;
    top: 15px;
}

img {
    border-radius: 5%;
}

.portfolio-body-box {
    height: 50px;
    width: 800px;
    border: 10px solid goldenrod;
    box-shadow: 3px 3px 0px #313131;
    background-color: #FFF;
    background-color: rgb(241, 224, 181);
    cursor: pointer;
    margin: 4% auto;
    display: block;
}

.portfolio-body-box:hover{
    background-color:  rgb(137, 126, 101);
    border: 10px solid rgb(126, 96, 18);
    box-shadow: 1px 1px 0px #313131;

}

.portfolio-body-box p {
    margin: auto 0px;
    position: relative;
    text-align: center;
    font-family: 'dogica', sans-serif;
    font-size: 200%;
    margin-top: 14px;
}

.portfolio-img {
    max-width: 500px;
    margin: 10px;
    cursor: pointer;
    height: 500px;
}

.img-container{
    float: left; 
    height: 200px;
}

.logo-subtitle{
    text-align: center;
    width: 100%;
    float: none;
    font-family: 'dogica', sans-serif;
    margin: 25px 0;

}

.mock-img{
    max-width: 300px;
    align-items: center;
    border: 1px solid gray
}

.mock-sub{
    width: 300px;
    text-align: center;
    font-family: 'dogica', sans-serif;
}

.mock-big-container{
    width: fit-content;
    margin: 0px auto;
    height: 400px;
    display: flex;
}

.mock-container{
    float: left;
    margin: 20% 10px 16px 0px;
    position: relative;
}

.sub-3 {
    width: 300px;
    text-align: center;
}

.mock-3 { 
    margin-top: 140px;
}

.art-box {
    left: 5%;
    margin-top: 6%;
}

.art-box p {
    width: 100%;
    text-align: center;
}

.mock-box {
    left: 28.5%;
}

.prototype-box {
    right: 28.5%;
}

.prototype-box{
    overflow-wrap: break-word;
}

.graphic-box {
    right: 5%;
}

#folio {
    padding-top: 50px;
    background-color: #fff;
    margin: calc(50vh - 242px) auto auto auto;
    width: 590px;
    height: 435px;
    font-family: "Helvetica", sans-serif;
    box-shadow: 5px 5px 0px #000;
    border-radius: 5px;
    text-decoration: none;
    margin-top: calc(50vh - 242px);
}

#rt, #pt, #folio hr{
    position: relative;
}

#folio hr {
    bottom: 215px;
    border: 1px solid rgb(154, 154, 154);
}

@media only screen and (max-width: 3300px) and (min-height: 1725px) {
    @keyframes flutter{
        0%{
            top:  900px;
        }
        50%{
            top: 915px;
        }
        100%{
            top: 900px;
        }
    }
    
    html {
        overflow-y: hidden;
    }

    #tree {
        transform: scale(1.5);
        top: 920px;
        position: absolute;
        left: 1040px;
        z-index: -2;
    }


    .leaf {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 40px 20px;
        border-color: transparent transparent #78a43e transparent;
        border-radius: 50%/30px 30px 0 0;
        transform: rotate(45deg);
        position: relative;
        left: 1000px;
        margin-left: 1000px;
        top: 500px;
        z-index: 3;
    }      

    #folio {
        transform: scale(2);
    }

    body{
        overflow-y: hidden;
    }

    .bird {
        left: 1990px;
        top:  900px;
        transform: scaleX(-1) scale(2);
    }
    
    .birdShadow{
        top: 1330px;
        z-index: 0;
    }

    .cloud3 {
        top: 1100px
    }

    .cloud4 {
        top: 800px;
    }

    .cloud6 {
        top: 420px;
    }

    .cloud7 {
        top: 1000px;
    }

    .cloud5 {
        top: 720px;
    }

    .portfolio-body-box {
        height: 200px;
        width: 800px;
        border: 10px solid goldenrod;
        box-shadow: 3px 3px 0px #313131;
        background-color: rgb(241, 224, 181);
        cursor: pointer;
        margin: 15% auto;
        display: block;
    }


    
    .portfolio-body-box p {
        margin-top: 85px;
        font-size: 36px;
    }

    .resumeContainer {
        height: fit-content;
        width: fit-content;
        margin: auto;
        padding-left: 15px;
    }
    
    .resumeContainer div {
        float: left;
        height: 100%;
        border-radius: 5px;
        margin: 150px 25px;
    }

    .resume-thumbnail {
        float: left;
        height: 40vw;
        outline: #000 1px solid;
        border-radius: 5px;
        margin: 25px 25px;
        cursor: pointer;
    }

    .resume-subtitle {
        text-align: center;
        font-family: 'dogica', sans-serif;
        top: 25px;
        font-size: 245%;
        line-height: 200%;
        /* height: 300px; */
    }

    .portfolioHeader {
        height: 150px;
        margin: 0;
        padding-top: 20px;
        background-color: #1666e5;
        font-size: 16px;
        color: white;
        text-align: center;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        font-family: 'dogica', sans-serif;
        border-bottom: 2px solid #313131;
    }

    .portfolioClose {
        position: relative;
        float: right;
        right: 9px;
        top: 0px;
        font-size: 38px;
        background-color: red;
        height: 100px;
        width: 120px;
        border-radius: 5px;
        box-shadow: inset -6px -6px 0px 0px darkred;
        padding-top: 25px;
        cursor: pointer;
        border: 4px solid  rgb(63, 0, 0);
    }
    
    .portfolioClose:hover {
        background-color: rgb(132, 0, 0);
        box-shadow: inset 6px 6px 0px 0px rgb(63, 0, 0);
        padding-left: 6px;
        right: 9px;
        padding-top: 25px;
        width: 114px;
        height: 100px;
    }

    .xx {
        font-size: 64px;
    }
    .portfolioTitle{
        left: 30px;
        font-size: 35px;
        text-align: center;
        position: relative;
        top: 45px;
    }

    .back-arrow {
        float: left;
        margin: 27px 0px 16px 40px;
        cursor: pointer;
        height: 100%;
    }
}