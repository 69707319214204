body {
    overflow-x: hidden;
}

#pageThree{
    text-align: center;
	background-size: cover;
	background-image: url("./index-images/abstract-blank-board-296884.jpg");
	overflow-x: hidden;
	height: 100vh;
}

.thumbnail1, .thumbnail2, .thumbnail3{
	border-style: solid;
	border-color: black;
	box-shadow: 3px 3px 15px #000;
	max-width: 125px;
}

.thumbnail1, .sub1 {
	margin-right: 400px;
}


.thumbnail2{
    max-width: 125px;
    margin-top: -400px;
	position: relative;
	bottom: 50px;
}

.thumbnail3 {
	position: relative;
	bottom: 55px;
}

.sub2{
    margin-top: -34px;
	position: relative;
	bottom: 5px;
}

/** LIGHTBOX MARKUP **/
.cv-lightbox, .cv-lightbox2, .cv-lightbox3 {
	/** Default lightbox to hidden */
	display: none;
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
}

.cv-lightbox img, .cv-lightbox2 img, .cv-lightbox3 img{
	/* pad lightbox image */
	max-width: 90%;
	max-height: 80%;
	margin-top: 2%;
}

.cv-lightbox:target, .cv-lightbox2:target, .cv-lightbox3:target {
	/** Remove default browser outline */
	outline: none;

	/** Unhide lightbox **/
	display: block;
}

.sub3 {
	margin: -33px 0px 16px 408px;
	position: relative;
	bottom: 12px;
}
.thumbnail3{
    max-width: 125px;
	margin: -200px 0px 16px 408px;
}

#cv-formContainer {
    text-align: center;
    border-style: solid;
    border-color: black;
    width: 350px;
    height: 400px;
    float: right;
    margin-right: 300px;
    border-radius: 5%;
    background-color: white;
    /* opacity: 0; */
}

@media only screen and (max-width: 3300px) and (min-height: 1725px) {
	#cv-formContainer{
        transform: scale(3);
        margin-top: 40vh;
        margin-right: 40vw;
        
    }
	
	#sampleBox{
		transform: scale(2.5);
		padding-top: 15vh;
		margin-right: 0px;
	}

	#sampleTitle{
		transform: scale(2.5);
		padding-top: 20vh;
	}

	.sample-subtext {
		padding-top: 21vh;
	}

	.sample-subtext p{
		transform: scale(2.7);
	}

}