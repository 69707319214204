@font-face {
    font-family: "PeanutButter";
    src: url("PeanutButter.ttf");
}

@font-face {
    font-family: "Rightwood";
    src: url("Rightwood.ttf");
}

@font-face {
    font-family: "dogica";
    src: url("dogica.ttf");
}

::-webkit-scrollbar {
  width: 13px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-thumb {
    background: #1666e5; /* Color of the scrollbar handle */
    box-shadow: inset -3px -1px 0px 0px #104aa6;
    outline: #212427;
    border-radius: 3px; /* Rounded corners of the scrollbar handle */
    width: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #104aa6; /* Color of the scrollbar handle on hover */
}

/**********************/
/* vvvvv Resume vvvvv */
/**********************/
.section-divider{
    font-size: 36px;
    font-family: 'dogica', sans-serif;
    text-align: center;
}

.contact-info li, .coursework-classes, .skill-cat, .cv-highlights li, .jobDesc {
    font-size: 16px;
    color: #212427;
}

.cv-highlights {
    margin: 0px 0px 25px 10px;
}

.cv-highlights li {
    margin-left: 10px;
}

.cv-highlights, .contact-info {
    line-height: 250%;
    list-style-type: square;
}
.divida {
    width: 300px;
    height: 20px;
    stroke: black 3px;
    margin-left: 250px;
}

.coursework, .jobDate, .edu-date, .jobTitle, .edu-title {
    font-size: 20px;
    font-weight: bold;
}

.jobTitle {
    margin-left: 15px;
}

.bold-text {
    font-weight: bold;
}

.coursework, .experience-title, .name-position, .summary-info, .contact-info {
    margin-left: 10px;

}

.coursework {
    margin-left: 20px;
}

.educationDate, .jobDate, .edu-date {
    float: right;
}

.jobTitle, .edu-title {
    float: left;
    width: 40%;
    padding-left: 10px;
}

.name-position, .summary-info {
    font-size: 24px;
    font-weight: bold;
    margin: 25px 0px 0px 0px
}

.summary-info {
    font-family: 'dogica', sans-serif;
    margin: 25px 0px 25px 20px;
}

.contact-info { 
    margin: 25px 0px 25px 20px;
}

.contact-info li {
    text-decoration: none;
}

.coursework {
    font-weight: bold;
    margin-bottom: 0px;
}

.coursework-classes {
    margin: 0px 0px 100px 50px;
    line-height: 250%;
    margin-left: 50px;
    padding-right: 50px;
}

.educationTitle {
    float: left;
    width: 325px;
    font-family: 'dogica', sans-serif;
}

.experience-title {
    font-size: 24px;
    font-weight: bold;
    font-family: 'dogica', sans-serif;
    margin: 30px 0px 10px 20px;
}

hr {
    border-top-color: #212427;
    border-top-width: thin;
    border-top-style: dashed;
}

.jobBody {
    line-height: 200%;
    margin-top: 45px;
    padding-right: 20px;
}

.jobDate {
    padding-right: 20px;
}

.jobDesc {
    font-weight: normal;
    margin-left: 15px;
    list-style-type: square;
    margin: 16px 0px 15px 15px;
}

.jobTitleContainer {
    display: inline-block;
    white-space: nowrap; 
    height: 30px;
    width: 100%;
    margin-bottom: 0px;
}

.name-position {
    margin-left: 20px;
    font-family: 'dogica', sans-serif;
    font-size: 24px;
}

.skill-cat {
    margin: 10px 0px 0px 40px;
    line-height: 140%;
}

.home-wrapper {
    font-family: 'Helvetica', sans-serif;
    padding: 7px 0px 0px 5px;
    margin-bottom: 0px;
    height: 100vh;
    width: 800px;
    overflow-x: hidden;
    background-color:#FFF;
    border-right: 15px solid #1666e5;
    position: absolute;
    color:#212427;
    overflow-y: scroll;
    z-index: 4;
    background-color: #FCF5E5;
}

.wrapper::-webkit-scrollbar {
    width: 6px;
}

.wrapper::-webkit-scrollbar-thumb {
    background-color: #0e49a5;
    border-radius: 20px;
}

.wrapper::-webkit-scrollbar-track {
    background-color: #fff; 
}
/**********************/
/* ^^^^^ Resume ^^^^^ */
/**********************/

/*****************************/
/* vvvv Wrapper Ribbons vvvv */
/*****************************/
.ribbon, .ribbon1, .ribbon2, .ribbon3 {
    position: absolute;
    background: #1666e5;
    text-align: center;
    color: #fff;
    animation-name: ribbonSweep;
    animation-duration: 1s;
    animation-iteration-count: 1;
    border-top-right-radius: 7px;
    margin: 2em auto 3em;
    padding: 1em 2em;
    left: 820px;
    width: 100px;
    font-size: 16px !important;
    height: 75px;
    cursor: pointer;
}

.ribbon {
    top: 0px;
}

.ribbon1 {
    top: 125px;
}

.ribbon2 {
    top: 250px;
    cursor: pointer;
}

.ribbon3 {
    top: 375px;
}

@keyframes ribbonWave {
    0%{
        left: 82px;
        right: -2em;
    }
    50%{
        left: 90px;
        right: -2.5em;
    }
    100%{
        left: 82px;
        right: -2em;
    }
}

.ribbon:before, .ribbon:after, .ribbon1:before, .ribbon1:after, .ribbon2:before, .ribbon2:after, .ribbon3:before, .ribbon3:after {
    content:"";
    position: absolute;
    display: block;
    border: 1.2em solid #0e49a5;
    border-right-color: transparent;
    bottom: -.71em;
    left: 82px;
}

.ribbon:after, .ribbon1:after, .ribbon2:after, .ribbon3:after {
    right: -2em;
    border-left-width: 3em;
    border-top-width: 3em;
    border-bottom-width: 2.5em;
    border-right-color: transparent;
    animation: ribbonWave 4s;
    animation-delay: 4s;
    animation-iteration-count: infinite;
    z-index: -1;
    
}

.ribbon:before, .ribbon1:before, .ribbon2:before, .ribbon3:before {
    left: -2em;
    border-right-width: 1.2em;
    border-color: transparent;
}

.ribbon a, .ribbon1 a, .ribbon2 a, .ribbon3 a {
    position: absolute;
    top: 30px;
    text-decoration: none;
    font-size: 16px;
    color: white;
    font-family: 'dogica', sans-serif;
    cursor: pointer;
}

.ribbon a {
    left:15px;
    top: 45px;
}

.ribbon1 a {
    top: 45px;
    left:33px;
}

.ribbon2 a {
    top: 45px;
    left:11px;
}

.ribbon3 a {
    left: 18px;
    top: 35px;
}

.ribbon a:visited, .ribbon2 a:visited {
    color: white;
    text-decoration: none;
}

@keyframes ribbonFoldWave {
    0%{
        left: 902px;
        width: 75px;
    }
    50%{
        left: 910px;
        width: 67px;
    }
    100%{
        left: 902px;
        width: 75px;
    }
}

.ribbonfold, .ribbonfold1, .ribbonfold2, .ribbonfold3 {
    position: absolute;
    border: 4px solid #0a3982;
    border-bottom-color: transparent;
    border-right-color: transparent;
    z-index: 0;
    width: 73px;
    left: 902px;
    animation: ribbonFoldWave 4s infinite;
    animation-delay: 4s;
}

.ribbonfold {
    top: 139px;
}

.ribbonfold1 {
    top: 264px;
}

.ribbonfold2 {
    top: 389px;
}

.ribbonfold3 {
    top: 514px;
}
/*******************************/
/* ^^^^^ Wrapper Ribbons ^^^^^ */
/*******************************/

/*****************************/
/* ^^^^^ Mobile Styles ^^^^^ */
/*****************************/
@media only screen and (max-width: 3300px) and (min-height: 1725px) {
    .scroll-relief {
        height: 200px;
    }
    
    body {
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        text-size-adjust:100%; 
    }

    .coursework, .coursework-classes, .jobDesc, .skill-cat, .contact-info li, .cv-highlights li {
        font-size: 300%;
    }
    .minor {
        font-size: 90%;
    }

    .coursework-classes {
        line-height: 150%;
        padding-right: 25px;
        margin-bottom: 400px;
    }

    .jobBody{
        margin-top: 90px;
    }
    
    .wrapper {
        width: 1000px;
        padding-left: 15px;
    }

    .cvBody{
        height: 100%;
    }

    @keyframes ribbonFoldWave {
        0%{
            left: 898px;
            width: 75px;
        }
        50%{
            left: 906px;
            width: 68px;
        }
        100%{
            left: 898px;
            width: 75px;

        }
    }

    .ribbon, .ribbon1, .ribbon2, .ribbon3 {
        left: 815px;
        width: 350px;
        height: 150px;
    } 

    .ribbon a, .ribbon1 a, .ribbon2 a, .ribbon3 a {
        font-size: 36px;
        margin: 25px 0px 0px 30px;
    }

    .ribbon a {
        margin-left: 45px;
    }

    .ribbon1 a {
        margin-left: 65px;
    }

    .ribbon2 a {
        margin-left: 35px;
    }

    .ribbon3 a {
        margin: 17px 0px 0px 45px;
    }

    .ribbonfold, .ribbonfold1, .ribbonfold2, .ribbonfold3 {
        left: 1897px;
        width: 322px;
    }

    @keyframes ribbonFoldWave {
        0%{
            left: 898px;
            width: 322px;
        }
        50%{
            left: 906px;
            width: 316px;
        }
        100%{
            left: 898px;
            width: 322px;
        }
    }

    .ribbonfold{
        top: 265px;
    }

    .ribbonfold1{
        top: 490px;
    }

    .ribbonfold2{
        top: 715px;
    }

    .ribbonfold3{
        top: 940px;
    }

    .cv-highlights {
        line-height: 400%;
        margin-bottom: 30px;
    }

    .cv-highlights li{
        margin: 0px 10px 7px 0px;
        list-style-type: square;
    }

    .jobDate {
        font-size: 36px;
        padding-right: 20px;
    }

    .jobDesc {
        margin-bottom: 10px;
        line-height: 150%;
    }
    
    .jobTitle{
        font-size: 36px;
        padding-left: 10px;
        font-weight: bold;
    }

    .ribbon{
        top: 50px;
    }

    .ribbon1 { 
        top: 275px;
    }

    .ribbon2 {
        top: 500px;
    }

    .ribbon3 {
        top: 725px;
    }

    .ribbon2:after, .ribbon:after, .ribbon1:after, .ribbon3:after {
        right: -1.5em;
        border-left-width: 3em;
        border-top-width: 4.5em;
        border-bottom-width: 4.5em;
        border-right-color: transparent;
        z-index: -1;
    }

    .skill-cat {
        margin-bottom: 45px;
    }

    .experience-title, .summary-info, .name-position {
        font-size: 500%;
        margin-left: 30px;
    }

    .name-position {
        font-size: 500%;
    }

    .contact-info li {
        margin-bottom: 25px;
    }

    .contact-info li:first-of-type {
        margin-top: 25px;
    }

    .wrapper::-webkit-scrollbar-thumb{
        background-color: #0e49a5;
        border-radius: 20px;
        border-width: 50px;
    }

    .wrapper::-webkit-scrollbar {
        width: 15px;
    }

    .coursework {
        margin-bottom: -40px;
    }
}