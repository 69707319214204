 .home2, .bio2, .resume2, .audio2, .video2, .blog2, .portfolio2, .wrapper, .wrappertext, .cvTitle, .sorry-tbar {
    position: absolute;
}

.home2:hover, .bio2:hover, .resume2:hover, .audio2:hover, .video2:hover, .blog2:hover  {
    -webkit-filter:invert(100%);
}

.bio2, .resume2, .audio2, .video2, .blog2, .portfolio2, .home2 {
    transform: rotate(45deg);
    box-shadow: 2px 2px 2px #000;
    -webkit-transition: all 1s;
    -webkit-filter: invert(0%);
    height: 50px;
    margin-left: 767px;
}

.home2 {
    margin-top: 100px;
}

.bio2{
    margin-top: 175px;
}

.resume2 {
    margin-top: 250px;
}

.audio2 {
    margin-top: 325px;
}

.video2{
    margin-top: 400px;
}

.blog2{
    margin-top: 400px;
}

.portfolio2 {                    
    margin-top: 475px;
}

.sorry-wrapper {
    width: 750px;
    height: 110vh;
    background-color: #ffffed;
    border: 2px solid #a8a8a8;
    font-family: "Helvetica";
    box-shadow: 2px 2px 2px #000;
    margin-top: -25px;
    /* z-index: -2; */
}

.sorry-wrappertext {
    margin: 100px 7px 16px 30px;
    text-align: center;
}

.degreetxt {
    font-size: 16px;
}

.ed {
    font-size: 24px;
}

.fsu {
    font-size: 20px;
}

.degree {
    font-size: 18px;
}

.cvTitle {
    margin-right: 500px;
    padding-left: 250px;
    /* z-index: 4; */
}

.sorry-tbar {
    width: 800px;
    height: 20px;
    background-color: #85BF7E;
    border-radius: 45px;
    margin: 40px 0px 16px -20px;
    /* z-index: 999; */
    box-shadow: -1px 2px 3px #080809;
}
