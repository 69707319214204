.home2, .bio2, .resume2, .audio2, .video2, .blog2, .portfolio2, .cv-wrapper, .wrappertext, .cvTitle, .tbar2 {
    position: absolute;
}

.home2:hover, .bio2:hover, .resume2:hover, .audio2:hover, .video2:hover, .blog2:hover  {
    -webkit-filter:invert(100%);
}

.home2 {
    margin: 70px 0px 16px 740px;
    position: fixed;
}

.bio2 {
    margin: 175px 0px 16px 740px;
    position: fixed;
}

.resume2 { 
    margin-top: 250px;
}

.sorry-audio {
    margin-top: 250px;
}

.sorry-video {
    margin-top: 325px;
}

.sorry-blog {
    margin: 370px 0px 16px 740px;
    position: fixed;
}

.sorry-portfolio2 {   
    margin: 475px 0px 16px 740px;                 
    position: fixed;
}

.additional {
    margin-bottom: 100px;
}

.cv-wrapper {
    width: 750px;
    height: 100vh;
    background-color: #ffffed;
    border: 2px solid #a8a8a8;
    font-family: "Helvetica";
    box-shadow: 2px 2px 2px #000;
    margin-top: 0px;
    z-index: -1;
    overflow-y: scroll;
}

.wrappertext {
    margin: 74px 7px 16px 7px;
}

.degreetxt {
    font-size:16px;
}

.ed {
    font-size: 24px;
}

.cv-icon {
    transform: rotate(45deg) scale(.5);
}

.fsu {
    font-size: 20px;
}

.degree {
    font-size: 18px;
}

.cvTitle {
    margin-right: 500px;
    padding-left: 250px;
    z-index: 4;
}

.tbar2 {
    width: 800px;
    height: 20px;
    background-color: #85BF7E;
    border-radius: 45px;
    margin: 40px 0px 16px -20px;
    /* z-index: 2; */
    box-shadow: -1px 2px 3px #080809;
}